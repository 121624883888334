<template>
  <div class="auth-wrapper">
    <div class="auth-inner">
      <h1 class="text-center">Profil:</h1>
      <table class="table table-hover">
        <tr>
          <td>Foydalanuvchi:</td>
          <td>{{ user.username }}</td>
        </tr>
        <tr>
          <td>Saytdan foydalanish:</td>
          <td>{{ details.app_free ? "Bepul" : "Pullik" }}</td>
        </tr>
        <tr>
          <td>Narxi:</td>
          <td>{{ details.cost | narx }}</td>
        </tr>
        <tr v-if="order.access">
          <td>Qolgan vaqt:</td>
          <td>{{ order.info }}</td>
        </tr>
        <tr v-else>
          <td>A'zolik holati:</td>
          <td>Siz a'zo bo'lmagansiz</td>
        </tr>
      </table>
      <p class="text-center">
        <router-link :to="{ name: 'user_order' }">A'zo bo'lish</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import apiClient from "@/helpers/ApiClient";
export default {
  name: "account",
  data() {
    return {
      details: "",
    };
  },
  computed: {
    user: function () {
      return this.$store.getters["user/state"].user;
    },
    order: function () {
      return this.$store.getters["user/state"].order;
    },
  },
  methods: {
    save: function () {
      this.$toast("Saqlandi.");
      console.log(this.user);
    },
  },
  async mounted() {
    await apiClient
      .post("site/get-details")
      .then((response) => {
        this.details = response.data;
      })
      .catch(() => {
        this.$toast.error("Serverdan ma'lumot kelmadi.");
      });
    await this.$store.dispatch("user/order");
  },
};
</script>

<style scoped>
.auth-inner {
  background: white;
}
</style>
